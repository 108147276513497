const mediaWidth = (size, style) => {
  const q = `@media screen and (max-width: ${size}px)`
  return {
    [q]: {
      ...style
    }
  }
}

const mediaWidthAndHeight = (sizeW, sizeH, style) => {
  const q = `@media screen and (max-width: ${sizeW}px), screen and (max-height: ${sizeH}px)`
  return {
    [q]: {
      ...style
    }
  }
}

const gridColumnTemplate = () => {
  return {
    gridTemplateColumns: '20% repeat(12, 1fr) 20%',
    ...mediaWidth(992,{
      gridTemplateColumns: '5% repeat(12, 1fr) 5%',
    }),
    ...mediaWidth(600, {
      gridTemplateColumns: '20px repeat(12, 1fr) 20px',
    })
  }
}

export {
  mediaWidth,
  mediaWidthAndHeight,
  gridColumnTemplate
}
