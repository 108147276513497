import React from 'react';

const Logo = (props) => {
  return (
    <svg
   className={props.className}
   version="1.1"
   viewBox="0 155 800 300"
   id="SVGRoot">
<g>
	<g>
		<path d="M20.01,283.84c3.62-0.99,5.1-2.63,5.1-5.76v-74.74c0-2.96-1.48-4.61-5.1-5.43v-1.97h56.13
			c15.31,0,24.03,2.63,29.3,9.05c3.29,3.95,5.1,9.55,5.1,15.97c0,9.38-3.62,14.65-13.01,18.6c10.87,3.29,16.13,10.04,16.13,20.74
			c0,8.89-3.13,15.64-9.55,20.09c-6.09,4.28-12.02,5.59-25.35,5.59H20.01V283.84z M75.16,233.3c8.23,0,10.54-0.33,13.66-1.97
			c3.46-1.81,5.27-5.43,5.27-10.21c0-4.61-1.81-8.23-5.27-10.04c-3.13-1.65-5.27-1.97-13.66-1.97H41.41v24.2H75.16z M77.46,272.81
			c13.83,0,19.26-3.79,19.26-13.17s-5.43-13.17-19.26-13.17H41.41v26.34H77.46z"/>
		<path d="M128.64,283.67c3.62-0.99,5.1-2.63,5.1-5.92v-73.58c0-3.29-1.32-4.77-5.1-5.92v-2.31h73.25
			c3.46,0,4.94-1.32,6.09-5.1h2.14v23.54h-2.14c-1.15-3.79-2.63-5.27-6.09-5.27h-51.85v24.36h33.41c3.29,0,4.94-1.48,6.09-5.1h2.14
			v23.38h-2.14c-1.15-3.62-2.8-5.1-6.09-5.1h-33.41v26.17h55.8c3.29,0,4.94-1.32,6.09-5.1h2.14v23.37h-2.14
			c-0.99-3.78-2.63-5.1-5.92-5.1h-77.37V283.67z"/>
		<path d="M228.05,283.67c3.79-1.15,5.1-2.63,5.1-5.92v-74.08c0-3.13-1.48-4.77-5.1-5.6v-2.14h47.74
			c17.61,0,23.87,0.82,29.96,3.95c7.08,3.62,11.52,12.35,11.52,22.22c0,13.33-6.91,21.07-21.07,23.7l19.26,30.78
			c3.13,5.1,5.27,6.59,8.89,7.24l-0.17,2.14h-27v-2.14c1.97-0.33,2.47-0.82,2.47-1.97c0-0.82-0.66-2.31-1.81-4.28l-19.26-30.12
			h-29.13v30.29c0,3.29,1.32,4.94,4.77,5.92v2.31h-26.17V283.67z M282.54,234.29c8.23,0,12.02-0.66,15.31-2.63
			c3.13-1.81,4.94-5.6,4.94-10.04c0-4.28-1.81-8.23-4.94-10.04c-3.29-1.97-6.75-2.47-15.31-2.47h-33.09v25.18H282.54z"/>
	</g>
	<g>
		<path d="M365.13,283.34c3.78-0.99,5.1-2.63,5.1-5.76V190.5h-35.89c-3.29,0-4.77,1.32-5.76,5.1h-2.63v-24.53h2.47
			c1.15,3.79,2.63,5.1,5.93,5.1h89.22c3.13,0,4.78-1.48,5.76-5.1h2.63v24.53h-2.63c-0.99-3.79-2.47-5.1-5.76-5.1h-35.89v87.08
			c0,3.29,1.32,4.77,5.11,5.76v2.64h-27.66V283.34z"/>
	</g>
	<g>
		<path d="M439.6,283.67c3.78-1.15,5.1-2.63,5.1-5.92v-74.07c0-3.13-1.48-4.77-5.1-5.6v-2.14h47.73
			c17.62,0,23.87,0.83,29.96,3.95c7.08,3.62,11.52,12.34,11.52,22.22c0,13.33-6.91,21.07-21.07,23.7l19.26,30.78
			c3.13,5.1,5.27,6.59,8.89,7.24l-0.17,2.14h-26.99v-2.14c1.97-0.33,2.47-0.82,2.47-1.97c0-0.82-0.66-2.31-1.81-4.28l-19.26-30.12
			H461v30.28c0,3.29,1.32,4.94,4.78,5.92v2.31H439.6V283.67z M494.09,234.29c8.23,0,12.02-0.66,15.31-2.63
			c3.13-1.81,4.94-5.6,4.94-10.04c0-4.28-1.81-8.23-4.94-10.04c-3.29-1.97-6.75-2.47-15.31-2.47H461v25.18H494.09z"/>
		<path d="M543.62,283.84c4.11-0.66,6.25-3.29,9.38-10.2l28.8-64.36c1.81-4.11,2.47-5.93,2.47-7.41
			c0-2.31-0.99-3.29-3.95-3.79v-2.14h34.73v2.31c-2.96,0.66-3.95,1.48-3.95,3.46c0,1.32,0.99,3.78,3.29,8.73l29.14,62.06
			c4.44,9.38,6.25,10.87,10.37,11.36v2.14h-29.14v-2.14c2.8-0.66,3.95-1.48,3.95-3.13c0-0.66,0-0.82-1.97-5.1l-5.76-12.67H574.4
			l-5.92,12.67c-0.66,1.48-1.15,3.13-1.15,4.11c0,1.97,1.32,3.29,4.11,4.11v2.14h-27.82V283.84z M614.73,249.77l-17.78-39.83
			L580,249.77H614.73z"/>
		<path d="M662.13,283.84c3.62-1.15,5.1-2.8,5.1-6.09v-73.58c0-3.29-1.32-4.78-5.1-5.93v-2.31h35.06v2.31
			c-2.8,0.83-4.11,1.97-4.11,3.79c0,0.99,0.5,2.3,2.14,4.94l25.68,43.62l23.87-43.79c1.81-3.29,1.81-3.46,1.81-4.77
			c0-1.97-0.66-2.64-4.12-3.79v-2.31H779v2.31c-3.79,0.99-5.27,2.64-5.27,5.93v73.58c0,3.29,1.32,4.94,5.1,6.09v2.14h-26.5v-2.14
			c3.62-1.15,5.1-2.8,5.1-6.09v-63.54l-37.53,67.16l-38.19-67.16v63.54c0,3.29,1.32,4.94,5.1,6.09v2.14h-24.69V283.84z"/>
	</g>
</g>
<g>
	<path d="M149.32,425.01c4.28-0.66,5.76-1.97,9.55-10.37l39.01-86.75c1.15-2.64,1.48-3.46,1.48-4.28
		c0-1.65-0.99-2.47-3.95-3.13v-2.64h32.1v2.64c-2.96,0.82-3.95,1.64-3.95,3.29c0,0.99,0.17,1.15,1.48,4.11l39.51,83.62
		c5.6,11.85,5.43,12.18,11.52,13.5v2.64h-30.62v-2.64c3.13-0.33,4.77-1.48,4.77-3.45c0-0.99-0.5-2.14-1.81-4.94l-8.23-17.45h-57.61
		l-8.23,18.6c-1.15,2.8-1.32,3.29-1.32,4.11c0,1.81,1.32,2.64,4.77,3.13v2.64h-28.48V425.01z M233.6,384.84l-22.88-49.06
		l-21.73,49.06H233.6z"/>
	<path d="M283.63,392.41v-46.58c0-3.29-1.32-4.94-5.1-5.93v-2.31h26.67v2.31c-3.79,0.99-5.27,2.64-5.27,5.93v47.73
		c0,8.89,1.97,14.49,6.42,17.45c4.61,3.13,12.51,4.94,20.9,4.94c8.39,0,16.46-1.81,20.9-4.94c4.45-2.96,6.26-8.39,6.26-17.45v-47.73
		c0-3.29-1.32-4.94-5.1-5.93v-2.31h24.86v2.31c-3.79,0.99-5.27,2.64-5.27,5.93v46.58c0,14.82-2.96,23.87-9.38,29.14
		c-6.25,4.94-19.1,8.06-33.25,8.06c-15.31,0-28.48-3.46-34.4-9.05C286.26,415.13,283.63,406.24,283.63,392.41z"/>
	<path d="M389.3,425.34c3.79-1.32,5.11-2.96,5.11-6.25v-72.92c0-3.45-1.32-5.1-5.11-6.25v-2.31h50.21
		c18.11,0,27.16,2.64,35.55,10.54c8.89,8.4,14.15,21.07,14.15,34.41c0,13.5-5.27,26.17-14.15,34.57
		c-8.56,7.9-17.45,10.54-35.55,10.54H389.3V425.34z M436.7,414.47c14.49,0,22.06-2.47,27.66-8.72c5.1-5.6,7.9-13.83,7.9-23.21
		c0-9.22-2.8-17.45-7.9-23.04c-5.59-6.42-13.17-8.73-27.66-8.73H410.7v63.7H436.7z"/>
	<path d="M504.84,425.5c3.62-1.15,5.1-2.8,5.1-6.09v-73.58c0-3.29-1.32-4.78-5.1-5.93v-2.31h26.66v2.31
		c-3.78,0.99-5.27,2.64-5.27,5.93v73.58c0,3.29,1.48,4.94,5.11,6.09v2.14h-26.5V425.5z"/>
	<path d="M546.16,382.7c0-13.34,5.92-26.5,15.64-34.9c9.38-8.23,20.9-12.02,36.05-12.02c15.47,0,26.83,3.79,36.21,12.02
		c10.04,8.73,15.64,21.4,15.64,35.55c0,12.84-5.92,25.85-15.64,34.24c-9.22,8.23-20.74,12.01-36.05,12.01
		c-15.47,0-26.99-3.78-36.21-12.01C551.91,409.04,546.16,396.04,546.16,382.7z M598,415.95c20.41,0,34.73-13.5,34.73-32.76
		c0-20.41-13.82-33.75-34.89-33.75c-20.74,0-34.74,13.5-34.74,33.25C563.11,402.46,577.26,415.95,598,415.95z"/>
</g>
</svg>

  );
}
export default Logo;