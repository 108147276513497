import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { colors } from '../constants'
import Build from '../assets/svg/build'
import Logo from '../assets/svg/logo'
import { mediaWidthAndHeight } from '../helpers'

const styles = {
  root: {
    width: '100%',
    display: 'grid',
    height: '100vh',
    minHeight: 450,
    maxHeight: '100vh',
    gridTemplateColumns: '1fr 30vw 1fr',
    gridTemplateRows: 'auto auto auto auto',
    background: `linear-gradient(35deg, ${colors.gradientDark} -20%, ${colors.gradientLight} 100%)`,
    ...mediaWidthAndHeight(860, 670, {
      gridTemplateColumns: '1fr 70vw 1fr',
      height: '70vh',
    }),
  },
  logo:  {
    marginTop: 70,
    fill: colors.white,
    height: 'auto',
    width: '100%',
    margin: '0px auto',
    maxWidth:  400,
    gridColumn: '2 / -2',
    gridRow: '1 / 2',
  },
  container: {
    gridColumn: '2 / -2',
    gridRow: '2 / 3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  build: {
    fill: colors.white,
    height: 'auto',
    width: '10vw',
    ...mediaWidthAndHeight(860, 670, {
      width: 120,
    }),
  },
  text: {
    color: colors.white
  }
}

class ComingSoon extends Component {
  render() {
    const { classes } = this.props
    return (
      <div
        className={classes.root}
      >
        <Logo className={classes.logo}/>
        <div className={classes.container}>
          <Build className={classes.build}/>
          <h3 className={classes.text}>New site is coming soon!</h3>
        </div>
      </div>
    )
  }
}
export default injectSheet(styles)(ComingSoon)
