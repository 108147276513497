import React, { Component } from 'react'
import injectSheet from 'react-jss'
import ComingSoon from './components/comingSoon'
import ContactUs from './components/contactUs'
import { mediaWidthAndHeight } from './helpers'

const styles = {
  root: {
    width: '200%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.7s',
    overflow: 'hidden',
    ...mediaWidthAndHeight(860, 670, {
      flexDirection: 'column',
      width: '100%',
    }),
  },
  mounted: {
    width: '100%',
    transition: 'all 0.7s',
  },
}

class App extends Component {
  constructor () {
    super()
    this.state = {
      loaded: false,
      mounted: false,
    }
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 200);
  }

  render() {
    const { classes } = this.props
    const { mounted } = this.state
    return(
      <div className={`${classes.root} ${ mounted ? classes.mounted : ''}`}>
        <ComingSoon/>
        <ContactUs/>
      </div>
    )
  }
}

export default injectSheet(styles)(App)
