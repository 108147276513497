import React from 'react';

const Build = (props) => {
  return (
    <svg
   className={props.className}
   version="1.1"
   viewBox="0 0 1024 1024"
   id="SVGRoot">
  <g>
	<g>
		<path d="M929.7,136.16c2.73,2.74,2.73,7.17,0,9.9c-1.37,1.37-3.16,2.05-4.95,2.05c-1.8,0-3.59-0.68-4.96-2.05l-15.5-15.5
			l-15.51,15.5c-1.37,1.37-3.16,2.05-4.95,2.05c-1.79,0-3.58-0.68-4.95-2.05c-2.74-2.73-2.74-7.16,0-9.9l15.51-15.5l-15.51-15.51
			c-2.74-2.74-2.74-7.17,0-9.9c2.73-2.74,7.16-2.74,9.9,0l15.51,15.5l15.5-15.5c2.74-2.74,7.17-2.73,9.91,0
			c2.73,2.73,2.73,7.16,0,9.9l-15.51,15.51L929.7,136.16z"/>
	</g>
</g>
<g>
	<g>
		<path d="M474.06,553.75c-1.82,0-3.66-0.31-5.47-0.97c-8.3-3.02-12.59-12.2-9.57-20.5l80.94-222.6c3.02-8.3,12.2-12.59,20.5-9.57
			c8.3,3.02,12.59,12.2,9.57,20.5l-80.95,222.6C486.73,549.71,480.59,553.75,474.06,553.75z"/>
	</g>
</g>
<path d="M455,772.76C448.6,730.5,412.02,698,368,698s-80.6,32.5-87,74.76c-0.66,4.32-1,8.74-1,13.24c0,0.25,0,0.51,0.01,0.76
	C280.41,834.93,319.73,874,368,874s87.59-39.07,87.99-87.24c0.01-0.25,0.01-0.51,0.01-0.76C456,781.5,455.66,777.08,455,772.76z
	 M368,714c35.18,0,64.55,25.36,70.77,58.76H297.23C303.45,739.36,332.82,714,368,714z M368,858c-39.45,0-71.59-31.89-71.99-71.24
	h143.98C439.59,826.11,407.45,858,368,858z"/>
<path d="M942.94,65H81.06c-11.6,0-21,9.4-21,21v679.76c0,11.6,9.4,21,21,21h105.8v6.95c0,8.38,5.94,15.77,14.13,17.56l23.83,5.2
	c1.21,5.11,2.71,10.17,4.47,15.12l-18.36,16.72c-6.22,5.64-7.67,15.01-3.47,22.26l7.28,12.63c4.26,7.37,12.9,10.7,21.02,8.11
	l25.27-8.05c3.18,3.37,6.52,6.59,9.99,9.62l-8.14,25.57c-2.58,8.1,0.76,16.74,8.13,21.01l12.61,7.28
	c7.25,4.19,16.62,2.74,22.27-3.46l18.68-20.53c4.09,1.26,8.24,2.34,12.43,3.23l5.94,27.23c1.8,8.19,9.18,14.13,17.54,14.13h14.57
	c8.37,0,15.75-5.94,17.56-14.15l5.93-27.21c4.18-0.89,8.34-1.97,12.43-3.23l18.68,20.52c5.63,6.21,15,7.66,22.26,3.47l12.61-7.28
	c7.37-4.26,10.72-12.9,8.13-21.01l-8.14-25.57c3.48-3.04,6.82-6.26,10-9.62l25.26,8.05c8.12,2.59,16.76-0.74,21.02-8.11l7.27-12.61
	c4.21-7.26,2.76-16.63-3.44-22.28l-18.38-16.72c1.76-4.95,3.26-10,4.48-15.12l23.82-5.2c8.19-1.79,14.13-9.18,14.13-17.56v-6.95
	h23.15c-0.03,0.16-0.06,0.32-0.09,0.48l-13.12,2.86c-6.7,1.46-11.57,7.5-11.57,14.37v9.81c0,6.87,4.87,12.91,11.56,14.37l14.46,3.15
	c0.62,2.43,1.33,4.84,2.13,7.21l-11.1,10.09c-5.16,4.7-6.33,12.2-2.84,18.23l4.91,8.49c3.42,5.95,10.65,8.75,17.2,6.66l15.47-4.93
	c1.45,1.49,2.95,2.93,4.48,4.32l-4.98,15.61c-2.1,6.65,0.65,13.72,6.67,17.19l8.48,4.89c5.95,3.44,13.61,2.25,18.23-2.82
	l11.49-12.61c1.77,0.51,3.55,0.98,5.34,1.39l3.64,16.71c1.48,6.7,7.52,11.56,14.36,11.56h9.81c6.85,0,12.89-4.86,14.38-11.58
	l3.64-16.69c1.78-0.41,3.56-0.88,5.33-1.39l11.47,12.59c4.62,5.1,12.29,6.29,18.24,2.85l8.5-4.91c6.01-3.48,8.75-10.54,6.65-17.2
	l-4.98-15.59c1.54-1.39,3.04-2.83,4.49-4.32l15.46,4.93c6.64,2.12,13.72-0.61,17.21-6.65l4.89-8.49c3.49-6.03,2.33-13.52-2.82-18.24
	l-11.1-10.1c0.8-2.38,1.51-4.78,2.13-7.2l14.44-3.15c6.71-1.46,11.58-7.5,11.58-14.37v-9.81c0-6.86-4.87-12.9-11.57-14.37
	l-13.12-2.86c-0.03-0.16-0.06-0.32-0.1-0.48h171.87c11.6,0,21-9.4,21-21V86C963.94,74.4,954.54,65,942.94,65z M532.67,793.71
	c0,0.93-0.64,1.73-1.55,1.92l-33.92,7.42l-1.03,5.12c-1.57,7.75-3.84,15.39-6.76,22.71l-2.06,5.15l26.5,24.12
	c0.67,0.61,0.82,1.62,0.36,2.43l-7.28,12.62c-0.46,0.8-1.4,1.15-2.29,0.87l-35.15-11.2l-3.45,3.93
	c-4.82,5.49-10.14,10.6-15.81,15.19l-4.25,3.44l11.43,35.88c0.28,0.88-0.09,1.83-0.89,2.29l-12.61,7.28
	c-0.79,0.46-1.81,0.3-2.42-0.38l-25.73-28.25l-4.99,1.74c-6.59,2.29-13.42,4.06-20.3,5.26l-5.3,0.92l-8.19,37.6
	c-0.2,0.91-1.01,1.57-1.93,1.57h-14.57c-0.91,0-1.72-0.66-1.91-1.55l-8.21-37.62l-5.3-0.92c-6.87-1.2-13.69-2.97-20.29-5.26
	l-4.99-1.74l-25.73,28.25c-0.61,0.68-1.63,0.84-2.43,0.38l-12.6-7.27c-0.81-0.47-1.18-1.42-0.9-2.3l11.43-35.88l-4.25-3.44
	c-5.64-4.57-10.96-9.68-15.81-15.2l-3.46-3.92l-35.13,11.2c-0.9,0.28-1.84-0.07-2.3-0.86l-7.29-12.65c-0.46-0.8-0.31-1.79,0.39-2.41
	l26.47-24.12l-2.05-5.15c-2.93-7.33-5.2-14.97-6.75-22.7l-1.02-5.13l-33.94-7.42c-0.91-0.19-1.55-0.99-1.55-1.92v-14.57
	c0-0.91,0.64-1.71,1.55-1.9l20.49-4.48l12.35-2.7l0.75-5.55c1.15-8.54,3.15-16.95,5.94-24.99l1.73-4.99l-23.97-21.81
	c-0.7-0.63-0.85-1.63-0.38-2.44l7.28-12.61c0.46-0.8,1.41-1.17,2.3-0.89l29.97,9.57l3.43-4.41c5.64-7.24,12.04-13.9,19.03-19.8
	l4.09-3.45l-9.3-29.13c-0.28-0.88,0.09-1.84,0.89-2.3l12.61-7.28c0.8-0.46,1.82-0.31,2.43,0.38l20.22,22.19l5.14-2.05
	c8.8-3.5,18.03-6.04,27.43-7.55l5.39-0.86l6.33-28.99c0.2-0.92,1.01-1.58,1.92-1.58h14.57c0.92,0,1.73,0.66,1.92,1.55l6.33,29.02
	l5.39,0.86c9.4,1.51,18.63,4.05,27.43,7.55l5.14,2.05l20.24-22.21c0.6-0.67,1.62-0.82,2.41-0.36l12.62,7.29
	c0.8,0.46,1.16,1.4,0.88,2.29l-9.28,29.13l4.08,3.45c6.99,5.91,13.39,12.57,19.02,19.81l3.43,4.4l29.97-9.56
	c0.89-0.29,1.84,0.08,2.3,0.88l7.29,12.62c0.46,0.81,0.31,1.81-0.37,2.43l-23.99,21.81l1.73,4.99c2.79,8.04,4.79,16.45,5.94,24.99
	l0.75,5.55l12.35,2.7l20.49,4.48h0.02c0.89,0.19,1.53,0.99,1.53,1.9V793.71z M779.86,805.51v7.73l-23.51,5.13l-1.02,5.13
	c-1.01,5.02-2.49,10.01-4.41,14.84l-2.05,5.15l18.39,16.74l-3.86,6.69l-24.29-7.74l-3.45,3.92c-3.16,3.59-6.64,6.93-10.35,9.94
	l-4.24,3.44l7.93,24.85l-6.69,3.86l-17.78-19.51l-4.98,1.72c-4.34,1.5-8.81,2.66-13.26,3.44l-5.3,0.92l-5.67,26.03h-7.74
	l-5.67-26.03l-5.3-0.92c-4.45-0.78-8.92-1.94-13.26-3.44l-4.98-1.72l-17.78,19.51l-6.69-3.86l7.92-24.85l-4.24-3.44
	c-3.69-2.99-7.16-6.33-10.33-9.94l-3.46-3.92l-24.3,7.74l-3.86-6.68l18.41-16.76l-2.06-5.15c-1.91-4.77-3.4-9.76-4.42-14.84
	l-1.03-5.12l-23.49-5.13v-7.73l22.87-4.98l0.76-5.54c0.29-2.17,0.68-4.37,1.14-6.54c0.72-3.34,1.64-6.64,2.74-9.8l1.73-4.98
	l-16.65-15.16l3.86-6.68l20.94,6.68l3.43-4.42c3.65-4.7,7.83-9.05,12.42-12.93l4.09-3.45l-6.47-20.29l6.69-3.86l14.13,15.51
	l5.15-2.05c5.72-2.29,11.75-3.94,17.9-4.93l5.39-0.86l4.43-20.27h7.72l4.41,20.27l5.4,0.86c6.16,0.98,12.18,2.64,17.92,4.93
	l5.14,2.05l14.13-15.51l6.69,3.86l-6.48,20.3l4.1,3.45c4.55,3.84,8.73,8.19,12.43,12.93l3.42,4.41l20.93-6.68l3.86,6.68
	l-16.63,15.15l1.71,4.97c1.1,3.18,2.02,6.49,2.74,9.79c0.47,2.2,0.86,4.4,1.15,6.57l0.76,5.53L779.86,805.51z M949.94,765.76
	c0,3.86-3.14,7-7,7H775.38l3.42-3.11c5.08-4.62,6.27-12.29,2.84-18.24l-4.91-8.51c-3.44-5.92-10.68-8.71-17.2-6.63l-11.44,3.65
	c-2.25-2.58-4.63-5.05-7.12-7.39l3.6-11.26c2.06-6.53-0.74-13.75-6.67-17.19l-8.48-4.89c-5.96-3.45-13.63-2.25-18.24,2.82
	l-7.48,8.22c-3.48-1.16-7.03-2.14-10.63-2.92l-2.35-10.78c-1.48-6.71-7.52-11.57-14.37-11.57h-9.81c-6.84,0-12.88,4.86-14.37,11.58
	l-2.35,10.77c-3.6,0.78-7.15,1.76-10.62,2.92l-7.48-8.21c-4.62-5.08-12.29-6.28-18.23-2.84l-8.49,4.9
	c-5.93,3.42-8.74,10.66-6.66,17.21l3.58,11.24c-2.49,2.35-4.87,4.81-7.11,7.39l-11.45-3.65c-6.53-2.08-13.77,0.71-17.19,6.65
	l-4.92,8.49c-3.44,5.96-2.23,13.64,2.85,18.24l3.42,3.11h-20.04c-2.13-5.53-6.94-9.86-12.96-11.16l-22-4.81
	c-1.03-5.91-2.42-11.75-4.16-17.45l16.26-14.79c6.2-5.66,7.64-15.02,3.45-22.27l-7.28-12.61c-4.26-7.37-12.89-10.71-21.01-8.13
	l-19.46,6.21c-4.26-5.06-8.85-9.84-13.74-14.28l6.07-19.05c2.55-8.01-0.87-16.85-8.13-21.02l-12.61-7.28
	c-7.27-4.2-16.64-2.74-22.25,3.46l-12.84,14.09c-6.57-2.32-13.33-4.18-20.19-5.55l-4.03-18.48c-1.81-8.18-9.19-14.12-17.55-14.12
	h-14.57c-8.36,0-15.73,5.94-17.55,14.14l-4.03,18.46c-6.86,1.37-13.61,3.22-20.19,5.55l-12.82-14.08c-5.63-6.21-15-7.67-22.27-3.47
	l-12.61,7.28c-7.26,4.18-10.68,13.02-8.13,21.02l6.08,19.05c-4.89,4.44-9.49,9.22-13.75,14.28l-19.46-6.22
	c-8.12-2.57-16.75,0.78-21.01,8.14l-7.27,12.6c-4.21,7.26-2.75,16.63,3.46,22.29l16.24,14.78c-1.74,5.7-3.13,11.54-4.16,17.45
	l-22,4.81c-6.02,1.3-10.83,5.63-12.96,11.16H81.06c-3.86,0-7-3.14-7-7V172.93h875.88V765.76z M949.94,160H74.06V86
	c0-3.86,3.14-7,7-7h861.88c3.86,0,7,3.14,7,7V160z"/>
<path d="M743,426.44c0.11,4.24-1.45,8.51-4.68,11.74L627.87,548.63c-3.13,3.13-7.22,4.69-11.32,4.69c-4.09,0-8.19-1.56-11.31-4.69
	c-6.25-6.25-6.25-16.38,0-22.62l99.57-99.57l-99.57-99.56c-6.25-6.25-6.25-16.38,0-22.63s16.38-6.25,22.63,0l110.45,110.46
	C741.55,417.94,743.11,422.21,743,426.44z"/>
<path d="M417.92,526.01c6.25,6.24,6.25,16.37,0,22.62c-3.13,3.13-7.22,4.69-11.32,4.69c-4.09,0-8.19-1.56-11.31-4.69L284.83,438.18
	c-3.23-3.23-4.79-7.5-4.68-11.74c-0.11-4.23,1.45-8.5,4.68-11.73l110.46-110.46c6.25-6.25,16.38-6.25,22.63,0s6.25,16.38,0,22.63
	l-99.57,99.56L417.92,526.01z"/>
<g>
	<path d="M726.38,783.4c-2.73-5.91-6.39-11.33-10.87-16.12C704.01,755.03,687.78,748,671,748s-33.01,7.03-44.52,19.29
		c-4.47,4.78-8.13,10.2-10.86,16.11c-3.73,8.05-5.62,16.67-5.62,25.6c0,33.64,27.36,61,61,61s61-27.36,61-61
		C732,800.07,730.11,791.45,726.38,783.4z M671,764c9.78,0,19.03,3.07,26.7,8.76h-53.4C651.98,767.07,661.22,764,671,764z M671,854
		c-24.81,0-45-20.19-45-45c0-6.59,1.39-12.95,4.14-18.88c0.53-1.15,1.11-2.27,1.73-3.36h78.26c0.62,1.09,1.2,2.21,1.73,3.36
		c2.75,5.93,4.14,12.29,4.14,18.88C716,833.81,695.81,854,671,854z"/>
</g>
</svg>
  );
}
export default Build;