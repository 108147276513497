import React, { Component } from 'react'
import emailjs from 'emailjs-com';
import injectSheet from 'react-jss'
import { colors } from '../constants'
import { mediaWidth, mediaWidthAndHeight } from '../helpers'

const styles = {
  root: {
    width: '100%',
    display: 'grid',
    height: '100vh',
    maxHeight: '100vh',
    minHeight: 550,
    gridTemplateColumns: '1fr 30vw 1fr',
    gridTemplateRows: 'auto auto auto auto',
    background: '#fff',
    ...mediaWidthAndHeight(860, 670, {
      gridTemplateColumns: '1fr 70vw 1fr',
    }),
  },
  header: {
    color: colors.mainColor,
    textAlign: 'center',
    fontSize: '1.2em',
    padding: '70px 0px 20px 0px',
    gridColumn: '2 / -2',
    gridRow: '1 / 2',
    ...mediaWidthAndHeight(860, 670, {
      padding: '30px 0px 25px 0px',
      height: 'auto',
      fontSize: '1.6em',
    }),
  },
  form: {
    width: 'auto',
    gridColumn: '2 / -2',
    gridRow: '2 / 3',
  },
  input: {
    display: 'block',
    margin: '50px auto',
    boxSizing: 'border-box',
    width: '100%',
    fontSize: '0.8em',
    padding: 5, 
    outline: 'none',
    border: 'none',
    borderBottom: `2px solid ${colors.mainColor}`,
    borderRadius: 2,
    color: colors.mainColor,
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    backgroundColor: 'transparent',
    ...mediaWidthAndHeight(860, 670, {
      margin: '20px auto',
      fontSize: '1.2em',
    }),
    '&::placeholder': {
      color: colors.mainColor,
    },
    '&:focus': {
      color: colors.black,
      borderColor: colors.black,
      '&::placeholder': {
        color: colors.black
      }
    },
    '&:hover': {
      color: colors.black,
      borderColor: colors.black,
      '&::placeholder': {
        color: colors.black
      }
    }
  },
  text: {
    resize: 'none',
    height: 200,
    borderRadius: 3,
    border: `2px solid ${colors.mainColor}`,
  },
  button: {
    display: 'block',
    color: colors.black,
    background: `linear-gradient(35deg, ${colors.gradientDark} -20%, ${colors.gradientLight} 100%)`,
    fontSize: '0.9em',
    borderRadius: 5,
    padding: 10,
    cursor: 'pointer',
    border: 'none',
    margin: '50px auto 100px auto',
    width: 'auto',
    ...mediaWidth(600, {
      margin: '25px auto 50px auto',
      fontSize: '1.3em',
    }),
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.white,
    }
  },
  disabled: {
    color: '#454545',
    background: '#a9a9a9',
    cursor: 'default',
    '&:hover': {
      color: '#454545',
      background: '#a9a9a9',
    }
  },
  message: {
    margin: '0px auto',
    border: '2px solid transparent',
    padding: 0,
    height: 0,
    color: '#00ff00'
  },
  messageActive: {
    height: 'auto',
    padding: 10,
    border: '2px solid #00ff00',
  }

}

class ContactUs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      message: '',
      valid: {
        name: false,
        email: false,
        text: false
      },
      form: {
        name: '',
        email: '',
        text: ''
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  validateData (type) {
    const { form, valid } = this.state
    if(type === 'name') {
      const validFeald = form.name.trim().length > 0
      this.setState({
        valid: {
          ...valid,
          name: validFeald
        }
      })
    } else if (type === 'text') {
      const validFeald = form.text.trim().length > 0
      this.setState({
        valid: {
          ...valid,
          text: validFeald
        }
      })
    } else if (type === 'email') {
      const validFeald = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(form.email);
      this.setState({
        valid: {
          ...valid,
          email: validFeald
        }
      })
    }
  }
  handleChange (event) {
    const { form } = this.state

    const value = event.target.value 
    const name = event.target.name
    this.setState({
      form: {
        ...form,
        [name]: value 
      }
    }, () => {
      this.validateData(name)
    })

  }
  handleSubmit (e) {
    e.preventDefault();
    const { name, email, text } = this.state.valid
    if (name && email && text) {
      emailjs.sendForm('smtp_server', 'template_DUOTKYyi', e.target, 'user_G6x5JmQXGr6y6NVrlIpnR')
        .then((result) => {
          this.setState({
            message: 'Message is sent!',
            valid: {
              name: false,
              email: false,
              text: false
            },
            form: {
              name: '',
              email: '',
              text: ''
            }
          })
        }, (error) => {
          this.setState({
            message: 'Something went wrong!'
          })
        });
    }
  }
  render() {
    const { classes } = this.props
    const { form, valid, message } = this.state
    const { email, text, name } = valid
    const isValid = name && text && email
    return (
      <div
        className={classes.root}
      >
        <h1 className={classes.header}>Contact Us</h1>
        <form
          onSubmit={(e) => this.handleSubmit(e)}
          className={classes.form}
        >
          <div className={`${classes.message} ${message.length > 0 ? classes.messageActive : null}`}>
            {message}
          </div>
          <input
            className={`${classes.input} ${classes.name}`}
            onChange={(e) => this.handleChange(e)}
            type="text"
            name="name"
            value={form.name}
            placeholder="Name..."/>
          <input
            className={`${classes.input} ${classes.email}`}
            onChange={(e) => this.handleChange(e)}
            type="email"
            name="email"
            value={form.email}
            placeholder="Email address..."/>
          <textarea
            className={`${classes.input} ${classes.text}`}
            onChange={(e) => this.handleChange(e)}
            type="text"
            name="text"
            value={form.text}
            placeholder="Text..."/>
          <input
            className={`${classes.input} ${classes.button} ${isValid ? null : classes.disabled}`}
            value="Send"
            type="submit"
          />

        </form>
      </div>
    )
  }
}
export default injectSheet(styles)(ContactUs)
