const colors = {
  mainColor: '#60B3FC',
  gradientLight: '#4FD8D3',
  gradientDark: '#315FE1',
  white: '#ffffff',
  black: '#111516'
}

export {
  colors,
}